





























































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { ActivityApplyDto, AuditFlowScope } from "@/api/appService";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

@Component({
  name: "ActivityApplyDetail",
  components: {  },
})
export default class ActivityApplyDetail extends Vue {

  created(): void {
    if ((this as any).$route.params.id) {
      this.id = Number((this as any).$route.params.id);
      this.fetchData({ id: this.id });
    }
  }

  detail: ActivityApplyDto = {};

  id = 0;



  // 获取表数据
  fetchData(params: any) {
    console.log("test:", params);
    return api.activityApply.get(params).then((res) => {
      this.detail = res;


    });
  }

  private cancel() {
    (this as any).$router.back();
  }
}
