var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.data.elementName === 'el-row')?_c('tr',[_vm._l((_vm.item.children),function(col,index){return [(col.formControl)?[_c('td',{key:col.formControl.fieldName+index,staticClass:"field-name",attrs:{"colspan":_vm.getFieldNameColSize(_vm.item.children.length)}},[_vm._v(" "+_vm._s(col.formControl.fieldDisplayName)+" ")]),_c('td',{key:col.formControl.fieldName+index+1,staticClass:"field-value",attrs:{"colspan":_vm.getFieldValueColSize(_vm.item.children.length)}},[(_vm.isSimpleUploadImage(col.formControl.elementType))?_c('img',{attrs:{"src":_vm._f("tryFormatDate")(_vm.getValue(col.formControl)),"alt":""}}):(_vm.isBatchUpload(col.formControl.elementType))?_c('file-list',{attrs:{"host-id":_vm.getHostId(col.formControl.id),"host-type":_vm.uploadHostType,"attachments":_vm.getValue(col.formControl)}}):(
            _vm.isProvinceCityAreaTownContent(col.formControl.elementType)
          )?_c('province-city-area-content',{attrs:{"hostData":_vm._f("tryFormatDate")(_vm.getValue(col.formControl))}}):(
            _vm.isProvinceCityAreaWithoutTownContent(col.formControl.elementType)
          )?_c('province-city-area-content',{attrs:{"hostData":_vm._f("tryFormatDate")(_vm.getValue(col.formControl))}}):(_vm.isSwitch(col.formControl.elementType))?_c('div',[_vm._v(" "+_vm._s(_vm._f("formatSwitch")(_vm.getValue(col.formControl)))+" ")]):(_vm.isSingleControl(col.formControl.elementType))?_c('single-control',{attrs:{"hostData":_vm._f("tryFormatDate")(_vm.getValue(col.formControl)),"control":col.formControl}}):(_vm.isMultipleControl(col.formControl.elementType))?_c('multiple-control',{attrs:{"hostData":_vm._f("tryFormatDate")(_vm.getValue(col.formControl)),"control":col.formControl}}):(_vm.isUeditorControl(col.formControl.elementType))?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getValue(col.formControl))}}):_c('div',[_vm._v(_vm._s(_vm._f("tryFormatDate")(_vm.getValue(col.formControl))))])],1)]:_vm._e()]}),(
      ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'el-divider'].some(
        function (type) { return type === _vm.item.data.elementName; }
      )
    )?_c('td',{attrs:{"colspan":_vm.colSize}},[(_vm.item.data.elementName === 'h1')?_c('h1',{staticClass:"text-center",class:("text-aline-" + (_vm.item.data.textAlign == undefined ? 'left' : _vm.item.data.textAlign))},[_vm._v(" "+_vm._s(_vm.item.data.elementContent)+" ")]):_vm._e(),(_vm.item.data.elementName === 'h2')?_c('h2',{staticClass:"text-center",class:("text-aline-" + (_vm.item.data.textAlign == undefined ? 'left' : _vm.item.data.textAlign))},[_vm._v(" "+_vm._s(_vm.item.data.elementContent)+" ")]):_vm._e(),(_vm.item.data.elementName === 'h3')?_c('h3',{staticClass:"text-center",class:("text-aline-" + (_vm.item.data.textAlign == undefined ? 'left' : _vm.item.data.textAlign))},[_vm._v(" "+_vm._s(_vm.item.data.elementContent)+" ")]):_vm._e(),(_vm.item.data.elementName === 'h4')?_c('h4',{staticClass:"text-center",class:("text-aline-" + (_vm.item.data.textAlign == undefined ? 'left' : _vm.item.data.textAlign))},[_vm._v(" "+_vm._s(_vm.item.data.elementContent)+" ")]):_vm._e(),(_vm.item.data.elementName === 'h5')?_c('h5',{staticClass:"text-center",class:("text-aline-" + (_vm.item.data.textAlign == undefined ? 'left' : _vm.item.data.textAlign))},[_vm._v(" "+_vm._s(_vm.item.data.elementContent)+" ")]):_vm._e(),(_vm.item.data.elementName === 'h6')?_c('h6',{staticClass:"text-center",class:("text-aline-" + (_vm.item.data.textAlign == undefined ? 'left' : _vm.item.data.textAlign))},[_vm._v(" "+_vm._s(_vm.item.data.elementContent)+" ")]):_vm._e(),(_vm.item.data.elementName === 'el-divider')?_c('p',[_vm._v(" "+_vm._s(_vm.item.data.elementContent)+" ")]):_vm._e()]):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }