






import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import { resolveSelectOptions } from "@/components/CustomForm/common";
import {
  CustomFormFieldCreateOrUpdateDto,
  SelectListItem,
} from "@/api/appService";

@Component({
  name: "SingleControl",
})
export default class SingleControl extends Vue {
  @Prop({ required: false })
  hostData?: any;

  @Prop({ required: false })
  control!: CustomFormFieldCreateOrUpdateDto;

  private selectList: SelectListItem[] = [];

  value: string | undefined = "";

  created() {
    this.initSelectOptions();
  }

  private initSelectOptions() {
    if (this.control.dataSource) {
      resolveSelectOptions(this.control).then((res) => {
        this.selectList = res;
        this.selectList.map((item) => {
          if (this.hostData == item.value) {
            this.value = item.text;
            return;
          }
        });
      });
    }
  }
}
