

























































import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import Base64 from "@/utils/Base64";
import api from "@/api";
import {
  ProvinceDto,
  CityDto,
  AreaDto,
  TownDto,
  AttachmentHostType,
} from "@/api/appService";

export interface ProvinceCityAreaDto {
  /**  */
  provinceId?: undefined;

  /**  */
  cityId?: undefined;

  /**  */
  areaId?: undefined;
}

@Component({
  name: "ProvinceCityAreaWithoutTown",
})
export default class ProvinceCityAreaWithoutTown extends Vue {
  // @Model("change", { type: Array, default: [] }) readonly fileIds!: number[];

  @Prop({ required: false })
  province!: undefined;

  @Prop({ required: false })
  city!: undefined;

  @Prop({ required: false })
  area!: undefined;

  @Prop() dataId?: number;
  @Prop({required:false,default:false})
  isDisableSelect!:boolean

  form?: ProvinceCityAreaDto = {};

  provinceList?: ProvinceDto[] = [];
  cityList?: CityDto[] = [];
  areaList?: AreaDto[] = [];

  created() {
    this.init();
  }

  @Watch("province")
  changeProvince(val: number | undefined) {
    if (!val) {
      this.form!.provinceId = undefined;
      this.form!.cityId = undefined;
      this.form!.areaId = undefined;
      this.provinceList = [];
      this.cityList = [];
      this.areaList = [];
      api.province.getAllList().then((res: any) => {
        this.provinceList = res!;
      });
    }
  }
  @Watch("dataId")
  init() {
    this.form!.provinceId = this.province == 0 ? undefined : this.province;
    this.form!.cityId = this.city == 0 ? undefined : this.city;
    this.form!.areaId = this.area == 0 ? undefined : this.area;
    this.updateData();
    if (!this.provinceList || this.provinceList.length === 0) {
      api.province.getAllList().then((res: any) => {
        this.provinceList = res!;
      });
    }

    // if (this.dataId ?? 0 > 0) {
    if (this.form!.provinceId) {
      this.showCityList(true);
    }

    if (this.form!.cityId) {
      this.showAreaList(true);
    }
    // }
  }

  showCityList(isInit: boolean) {
    if (!isInit) {
      this.$set(this.form!, "cityId", "");
      this.$set(this.form!, "areaId", "");
      //
      this.updateData();
    }

    api.city
      .getAllList({ provinceId: this.form!.provinceId })
      .then((res: any) => {
        this.cityList = res!;
      });
  }

  showAreaList(isInit: boolean) {
    if (!isInit) {
      this.$set(this.form!, "areaId", "");
      //
      this.updateData();
    }

    api.area.getAllList({ cityId: this.form!.cityId }).then((res: any) => {
      this.areaList = res!;
    });
  }

  setAreaValue(isInit: boolean) {
    if (!isInit) {
      this.updateData();
    }
    api.area.getAllList({ cityId: this.form!.cityId }).then((res: any) => {
      this.areaList = res!;
    });
  }

  updateData() {
    this.$emit("update:province", this.form!.provinceId);
    this.$emit("update:city", this.form!.cityId);
    this.$emit("update:area", this.form!.areaId);
  }
}
