











import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import ProvinceCityAreaWithoutTown from "@/components/ProvinceCityArea/indexWithoutTown.vue";

export interface ProvinceCityAreaDto {
  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  townId?: number;
}

@Component({
  name: "ProvinceCityAreaWithoutTownControl",
  components: {
    ProvinceCityAreaWithoutTown,
  },
})
export default class ProvinceCityAreaWithoutTownControl extends Vue {
  @Prop({ required: false, default: 0 })
  dataId?: number | string;

  @Model("change", { required: true })
  place!: ProvinceCityAreaDto;
}
