









































































import { Component, Prop, Vue } from "vue-property-decorator";
import { ILayoutItem } from "@/components/CustomForm/common";
import {
  AttachmentHostType,
  CustomFormFieldCreateOrUpdateDto,
  CustomFormType,
} from "@/api/appService";
import moment from "moment";
import FileList from "@/components/CustomForm/TableDetail/components/fileList.vue";
import ProvinceCityAreaContent from "@/components/CustomForm/TableDetail/components/ProvinceCityAreaContent.vue";
import SingleControl from "@/components/CustomForm/TableDetail/components/SingleControl.vue";
import MultipleControl from "@/components/CustomForm/TableDetail/components/MultipleControl.vue";

@Component({
  name: "TableRowItem",
  components: {
    MultipleControl,
    SingleControl,
    ProvinceCityAreaContent,
    FileList,
  },
  filters: {
    tryFormatDate(value: any, format = "YYYY-MM-DD") {
      if (value instanceof Date) {
        return moment(value).format(format);
      } else if (
        value &&
        typeof value === "string" &&
        /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2})/.test(value)
      ) {
        return moment(value).format(format);
      } else {
        return value;
      }
    },
    formatSwitch(value: any) {
      if (value) {
        return "是";
      } else {
        return "否";
      }
    },
  },
})
export default class TableRowItem extends Vue {
  @Prop({ required: true })
  item!: ILayoutItem;

  @Prop({ required: true })
  items!: ILayoutItem[];

  @Prop({ required: true })
  data!: any;

  @Prop({ required: true })
  colSize!: number;

  @Prop({ required: true })
  hostType!: CustomFormType;

  getValue(control: CustomFormFieldCreateOrUpdateDto) {
    let fieldName = "";
    if (control.isDynamic) {
      fieldName = control.fieldName!;
    } else {
      fieldName =
        control.fieldName!.substr(0, 1).toLowerCase() +
        control.fieldName!.substr(1);
    }

    if (this.data[fieldName]) {
      return this.data[fieldName];
    } else if (
      this.data["extensionObject"] &&
      this.data.extensionObject[fieldName]
    ) {
      return this.data.extensionObject[fieldName];
    } else {
      return "";
    }
  }

  get uploadHostType(): string {
    switch (this.hostType) {
      case CustomFormType.InternalProject:
        return AttachmentHostType.Internal_Project;
      case CustomFormType.Beneficiary:
        return AttachmentHostType.Beneficiary;
      case CustomFormType.SchoolServiceRecord:
        return AttachmentHostType.Internal_ServiceRecord;
      case CustomFormType.BeneficiaryServiceRecord:
        return AttachmentHostType.Internal_ServiceRecord;
      case CustomFormType.OrganizationUnit:
        return AttachmentHostType.OrganizationUnit;
      case CustomFormType.Volunteer:
        return AttachmentHostType.Volunteer;
      case CustomFormType.TrainManage:
        return AttachmentHostType.Temp;
      case CustomFormType.ApplicationPayment:
        return AttachmentHostType.ApplicationPayment;
      case CustomFormType.FundProject:
        return AttachmentHostType.FundProject;
      case CustomFormType.FundProjectApply:
        return AttachmentHostType.FundProjectApply;
      case CustomFormType.FundProjectModifyApply:
        return AttachmentHostType.FundProjectModifyApply;
      case CustomFormType.ActivityManage:
        return AttachmentHostType.ActivityManage;
      case CustomFormType.StarRatingManagement:
        return AttachmentHostType.StarRatingRecord;
      case CustomFormType.ServiceManagement:
        return AttachmentHostType.ServiceManage;
      default:
        return "";
    }
  }

  //判断图片
  isSimpleUploadImage(value: string) {
    return [
      "simple-upload-image",
      "upload-simple-image",
      "simpleUploadImage",
      "uploadSimpleImage",
    ].some((name) => name === value);
  }

  // 判断是否为单个选项
  isSingleControl(value: string) {
    return ["dropdownList", "dropdownlist", "select", "el-radio", "radio"].some(
      (name) => name === value
    );
  }
  // 判断是否外多个选项
  isMultipleControl(value: string) {
    return [
      "Multiple",
      "multiple",
      "MultipleSelect",
      "multipleSelect",
      "multipleselect",
      "multiple-dropdown-list",
      "multipledropdownlist",
      "multipleDropdownList",
      "MultipleDropdownList",
      "el-checkbox",
      "checkbox",
    ].some((name) => name === value);
  }
  // 判断附件
  isBatchUpload(value: string) {
    return ["batch-upload", "upload-batch"].some((name) => name === value);
  }

  // 判断省市区
  isProvinceCityAreaWithoutTownContent(value: string) {
    return [
      "province-city-area-without-town-control",
      "ProvinceCityArea",
      "provinceCityArea",
    ].some((name) => name === value);
  }
  // 判断省市区
  isProvinceCityAreaTownContent(value: string) {
    return [
      "province-city-area-town-control",
      "ProvinceCityAreaTownControl",
      "provinceCityAreaTownControl",
    ].some((name) => name === value);
  }
  //判断开关
  isSwitch(value: string) {
    return ["el-switch", "switch", "Switch"].some((name) => name === value);
  }

  // 判断富文本
  isUeditorControl(value: string) {
    return ["Ueditor", "editor", "u-editor"].some((name) => name === value);
  }
  // 获取附件hostId
  getHostId(formItemId: string) {
    return this.data.id + ":" + formItemId;
  }

  getFieldNameColSize(rowColCount: number) {
    return 4;
    // if (rowColCount === 1) {
    //   if (this.colSize > 10) {
    //     return (this.colSize / 10) * 2;
    //   } else {
    //     return (this.colSize / 10) * 3;
    //   }
    // } else {
    //   return (this.colSize / rowColCount / 10) * 3;
    // }
  }

  getFieldValueColSize(rowColCount: number) {
    let val = (this.colSize - rowColCount * 4) / rowColCount;
    return (this.colSize - rowColCount * 4) / rowColCount;
    // if (rowColCount === 1) {
    //   if (this.colSize > 10) {
    //     return (this.colSize / 10) * 8;
    //   } else {
    //     return (this.colSize / 10) * 7;
    //   }
    // } else {
    //   return (this.colSize / rowColCount / 10) * 7;
    // }
  }
}
