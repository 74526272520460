













import {Component, Prop, Vue, Model, Watch} from "vue-property-decorator";
import {AttachmentDto, AttachmentHostType} from "@/api/appService";
import api from "@/api/index";

interface FileItem {
  id: number;
  name: string;
  url: string;
}

@Component({name: "fileList"})
export default class fileList extends Vue {
  @Prop({required: true})
  attachments!: string;

  @Prop({required: true})
  hostId!: string | number;

  @Prop({required: true})
  hostType!: AttachmentHostType;

  private existsFileList: FileItem[] = [];

  get headers() {
    return {
      "Abp-OrganizationUnitId": (this as any).$store.getters["user/ouId"] || "",
      "Abp-TenantId": (this as any).$store.getters["app/tenantId"],
      Authorization: `Bearer ${(this as any).$store.getters["user/token"]}`,
      ".AspNetCore.Culture": "c=zh-Hans|uic=zh-Hans",
    };
  }

  // @Watch('hostId')
  // onHostIdChange(){
  //   this.loadExistsAttachments();
  // }
  //
  // @Watch('hostType')
  // onHostTypeChange(){
  //   this.loadExistsAttachments();
  // }

  async created() {
    await this.loadExistsAttachments();
  }

  async loadExistsAttachments() {
    if (this.attachments) {
      await api.attachmentService
        .getAttachmentsByIds({
          ids: this.attachments
        })
        .then((res) => {
          if (res) {
            this.existsFileList = res.map((attachment) => {
              return {
                id: attachment.id!,
                url: attachment.url!,
                name: attachment.fileName!,
              };
            });
          } else {
            this.existsFileList = [];
          }
        });
    }
  }
}
