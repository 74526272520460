












import { Component, Model, Prop, Vue, Watch } from "vue-property-decorator";
import ProvinceCityArea from "@/components/ProvinceCityArea/index.vue";

export interface ProvinceCityAreaTownDto {
  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;

  townId?: number;
}

@Component({
  name: "ProvinceCityAreaControl",
  components: {
    ProvinceCityArea,
  },
})
export default class ProvinceCityAreaControl extends Vue {
  @Prop({ required: false, default: 0 })
  dataId?: number | string;

  @Model("change", { required: true })
  place!: ProvinceCityAreaTownDto;
}
