




















































































import {Component, Vue} from "vue-property-decorator";
import api from "@/api/index";
import {
  ActivityManagementCreateOrUpdateDto,
  ActivityManagementDto,
  CustomFormType,
} from "@/api/appService";
import CustomFormDetailView from "@/components/CustomForm/FormDetail/index.vue";
import moment from "moment";
import CustomFormDataTableDetail from "@/components/CustomForm/TableDetail/index.vue";


@Component({
  name: "WorkOrderDetail",
  components: {
    CustomFormDetailView,
    CustomFormDataTableDetail
  },
})
export default class WorkOrderDetail extends Vue {
  private id = 0;
  detail: ActivityManagementDto = {
    activity: {
      leader: {
        surname: ""
      },
      activityTypeDictionary: {
        displayName: ""
      }
    }
  };
  loading = true;

  customFormId = 0;
  isFormReady = false;

  get leaderName() {
    if (this.detail && this.detail.activity && this.detail.activity.leader) {
      return this.detail.activity.leader.surname!
    }

    return ""
  }

  created() {
    this.id = parseInt(this.$route.params.id, 10);
    this.fetchData();
  }

  getTime(item: any) {
    return moment(item).format("HH:mm");
  }

  fetchData() {
    api.activityManagement
      .get({id: this.id})
      .then((result: ActivityManagementDto) => {
        this.detail = result;
        this.loading = false;
        this.fetchFormDetail();
      });
  }

  async fetchFormDetail() {
    const formId = await api.customFormService.getCustomFormId({
      hostType: CustomFormType.ActivityManage,
      hostId: "303",
    });
    this.custogetmFormId = parseInt(formId, 10);
    this.isFormReady = true;
  }

  fetchFormData(): Promise<ActivityManagementCreateOrUpdateDto> {
    return api.activityManagement.getForEdit({id: this.id});
  }
}
