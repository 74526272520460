




































import { Component, Prop, Vue } from "vue-property-decorator";
import { AreaDto, CityDto, ProvinceDto, TownDto } from "@/api/appService";
import api from "@/api/index";

export interface ProvinceCityAreaDto {
  /**  */
  provinceId?: number;

  /**  */
  cityId?: number;

  /**  */
  areaId?: number;
}

@Component({
  name: "ProvinceCityAreaWithoutTownView",
})
export default class ProvinceCityAreaWithoutTownView extends Vue {
  @Prop({ required: false, default: {} })
  hostData!: ProvinceCityAreaDto;

  provinceList?: ProvinceDto[] = [];
  cityList?: CityDto[] = [];
  areaList?: AreaDto[] = [];

  value = "";

  created() {
    this.init();
  }

  init() {
    Promise.all([
      this.fetchProvinceList(),
      this.fetchCityList(),
      this.fetchAreaList(),
    ]).then(() => {
      console.log(
        this.provinceList,
        this.cityList,
        this.areaList
      );
    });
  }

  fetchProvinceList() {
    return new Promise<ProvinceDto[]>((resolve, reject) => {
      if (this.provinceList && this.provinceList.length) {
        resolve(this.provinceList);
      } else {
        api.province.getAllList().then((res) => {
          this.provinceList = res ?? [];
          resolve(this.provinceList);
        });
      }
    });
  }

  fetchCityList() {
    return new Promise<CityDto[]>((resolve, reject) => {
      if (this.cityList && this.cityList.length) {
        resolve(this.cityList);
      } else if (!this.hostData?.provinceId) {
        resolve([]);
      } else {
        api.city
          .getAllList({ provinceId: this.hostData.provinceId })
          .then((res) => {
            this.cityList = res ?? [];
            resolve(this.cityList);
          });
      }
    });
  }

  fetchAreaList() {
    return new Promise<AreaDto[]>((resolve, reject) => {
      if (this.areaList && this.areaList.length) {
        resolve(this.areaList);
      } else if (!this.hostData?.cityId) {
        resolve([]);
      } else {
        api.area.getAllList({ cityId: this.hostData.cityId }).then((res) => {
          this.areaList = res ?? [];
          resolve(this.areaList);
        });
      }
    });
  }
}
