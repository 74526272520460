

























































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  AttachmentHostType,
  CustomFormDataSourceType,
  CustomFormFieldCreateOrUpdateDto,
  CustomFormType,
  SelectListItem,
} from "@/api/appService";
import api from "@/api/index";
import axiosInstance from "@/utils/request";
import {
  createValidator,
  fieldNameTryAsCamelCase,
  resolveSelectOptions,
} from "@/components/CustomForm/common";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import BatchUpload from "@/components/BatchUpload/indexForCustomForm.vue";
import ProvinceCityAreaControl from "@/components/ProvinceCityAreaControl/index.vue";

import ProvinceCityAreaView from "@/components/CustomForm/FormDetail/components/ProvinceCityAreaView.vue";

import validate from "@/utils/validate";
import { IValidate } from "@/components/CustomForm/Validators/IValidate";
import Ueditor from "@/components/Ueditor/index.vue";
import UploadedFilesView from "@/components/CustomForm/FormDetail/components/UploadedFilesView.vue";
import ProvinceCityAreaWithoutTownView
  from "@/components/CustomForm/FormDetail/components/ProvinceCityAreaWithoutTownView.vue";

@Component({
  name: "FormItemControl",
  components: {
    ProvinceCityAreaWithoutTownView,
    ProvinceCityAreaControl,
    SimpleUploadImage,
    BatchUpload,
    Ueditor,
    ProvinceCityAreaView,
    UploadedFilesView
  },
})
export default class FormItemControl extends Vue {
  @Prop({ required: true })
  control!: CustomFormFieldCreateOrUpdateDto;

  @Prop({ required: true })
  dto: any;

  @Prop({ required: true })
  formType!: CustomFormType;

  internalValue: any = "";

  private selectList: SelectListItem[] = [];
  private fieldName!: string;

  created() {
    this.fieldName = fieldNameTryAsCamelCase(this.control);
    this.initInternalValue();
  }

  get propName() {
    if (this.control.isDynamic) {
      if (this.isProvince()) {
        return `extensionObject.${this.fieldName}.townId`;
      } else {
        return `extensionObject.${this.fieldName}`;
      }
    } else {
      if (this.isProvince()) {
        return `${this.fieldName}.townId`;
      } else {
        return this.fieldName;
      }
    }
  }

  isProvince() {
    return [
      "province-city-area-town-control",
      "ProvinceCityAreaTownControl",
      "provinceCityAreaTownControl",
    ].some((name) => name === this.control.elementType);
  }

  get attachmentHostId() {
    return this.dto.id + ":" + this.control.id;
  }

  private initInternalValue() {
    let value: any;

    if (this.control.isDynamic == false) {
      // 固定字段部分处理
      // eslint-disable-next-line no-prototype-builtins
      if (this.dto.hasOwnProperty(this.fieldName)) {
        value = this.dto[this.fieldName];
      } else {
        this.$set(this.dto, this.fieldName, "");
        value = "";
      }
    } else {
      // dynamic filed
      // eslint-disable-next-line no-prototype-builtins
      if (!this.dto.hasOwnProperty("extensionObject")) {
        this.$set(this.dto, "extensionObject", {});
      }
      // eslint-disable-next-line no-prototype-builtins
      if (this.dto.extensionObject.hasOwnProperty(this.fieldName)) {
        value = this.dto.extensionObject[this.fieldName];
      } else {
        this.$set(this.dto.extensionObject, this.fieldName, "");
        value = "";
      }
    }
    if (this.isCheckBox) {
      if (value) {
        value = value.split(",");
      } else {
        value = [];
      }
    }

    this.internalValue = value;

    if (
      ["el-checkbox", "checkbox"].some(
        (name) => name === this.control.elementType
      ) ||
      [
        "Multiple",
        "multiple",
        "MultipleSelect",
        "multipleSelect",
        "multipleselect",
        "multiple-dropdown-list",
        "multipledropdownlist",
        "multipleDropdownList",
        "MultipleDropdownList",
      ].some((name) => name === this.control.elementType)
    ) {
      if (!this.internalValue) {
        this.internalValue = [];
      } else {
        if (!(this.internalValue instanceof Array)) {
          this.internalValue = this.internalValue.split(",");
        }
        // this.internalValue.map((item: any, index: number) => {
        //   if (Number(item)) {
        //     this.internalValue[index] = Number(item);
        //   }
        // });
      }
    }

    if (
      ["el-radio", "radio", "dropdownList", "dropdownlist", "select"].some(
        (name) => name === this.control.elementType
      )
    ) {
      if (value) {
        if (Number(value)) {
          this.internalValue = Number(value);
        }
      }
    }

    if (
      [
        "province-city-area-town-control",
        "ProvinceCityAreaTownControl",
        "provinceCityAreaTownControl",
      ].some((name) => name === this.control.elementType)
    ) {
      if (!value) {
        this.internalValue = {
          provinceId: undefined,
          cityId: undefined,
          areaId: undefined,
          townId: undefined,
        };
      }
    }

    if (
      [
        "province-city-area-without-town-control",
        "ProvinceCityArea",
        "provinceCityArea",
      ].some((name) => name === this.control.elementType)
    ) {
      if (!value) {
        this.internalValue = {
          provinceId: undefined,
          cityId: undefined,
          areaId: undefined,
        };
      }
    }

    if (
      ["el-switch", "switch", "Switch"].some(
        (name) => name === this.control.elementType
      )
    ) {
      if (!value) {
        this.internalValue = false;
      } else {
        this.internalValue = Boolean(value);
      }
    }

    //针对数据字典中的值只显示id值问题修复
    if (
      !(["el-checkbox", "checkbox"].some(
        (name) => name === this.control.elementType
      ) ||
      [
        "Multiple",
        "multiple",
        "MultipleSelect",
        "multipleSelect",
        "multipleselect",
        "multiple-dropdown-list",
        "multipledropdownlist",
        "multipleDropdownList",
        "MultipleDropdownList",
      ].some((name) => name === this.control.elementType))
    ){
      if(this.control.dataSource&&this.control.dataSource!.dataSourceType ===
        CustomFormDataSourceType.DataDictionary){
        if(value){
          this.internalValue=value.toString()
        }
      }
    }

    this.initSelectOptions();
  }

  private initSelectOptions() {
    if (this.control.dataSource) {
      resolveSelectOptions(this.control).then((res) => {
        this.selectList = res;
      });
    }
  }

  @Watch("internalValue")
  onInternalValueChange(newValue: any) {
    let value = "";
    if (newValue && newValue instanceof Array) {
      value = (newValue as []).join(",");
    } else {
      value = newValue;
    }
    if (!this.control.isDynamic) {
      this.dto[this.fieldName] = value;
    } else {
      this.$set(this.dto.extensionObject, this.fieldName, value);
    }
  }

  get uploadHostType(): string {
    switch (this.formType) {
      case CustomFormType.InternalProject:
        return AttachmentHostType.Internal_Project;
      case CustomFormType.Beneficiary:
        return AttachmentHostType.Beneficiary;
      case CustomFormType.SchoolServiceRecord:
        return AttachmentHostType.Internal_ServiceRecord;
      case CustomFormType.BeneficiaryServiceRecord:
        return AttachmentHostType.Internal_ServiceRecord;
      case CustomFormType.OrganizationUnit:
        return AttachmentHostType.OrganizationUnit;
      case CustomFormType.Volunteer:
        return AttachmentHostType.Volunteer;
      case CustomFormType.TrainManage:
        return AttachmentHostType.Temp;
      default:
        return "";
    }
  }

  get isSimpleUploadImage() {
    return [
      "simple-upload-image",
      "upload-simple-image",
      "simpleUploadImage",
      "uploadSimpleImage",
    ].some((name) => name === this.control.elementType);
  }

  get isMultipleSelect() {
    return [
      "Multiple",
      "multiple",
      "MultipleSelect",
      "multipleSelect",
      "multipleselect",
      "multiple-dropdown-list",
      "multipledropdownlist",
      "multipleDropdownList",
      "MultipleDropdownList",
    ].some((name) => name === this.control.elementType);
  }

  get isInput() {
    return (
      this.control.elementType === "el-input" ||
      this.control.elementType === "input"
    );
  }

  get isNumber() {
    return (
      this.control.elementType === "el-input-number" ||
      this.control.elementType === "number"
    );
  }

  get isTextarea() {
    return (
      this.control.elementType === "el-textarea" ||
      this.control.elementType === "textarea"
    );
  }

  get isSelect() {
    return ["dropdownList", "dropdownlist", "select"].some(
      (name) => name === this.control.elementType
    );
  }

  get isRadio() {
    return ["el-radio", "radio"].some(
      (name) => name === this.control.elementType
    );
  }

  get isCheckBox() {
    return ["el-checkbox", "checkbox"].some(
      (name) => name === this.control.elementType
    );
  }

  get isSwitch() {
    return ["el-switch", "switch", "Switch"].some(
      (name) => name === this.control.elementType
    );
  }

  get isDate() {
    return ["el-date", "date", "el-date-picker"].some(
      (name) => name === this.control.elementType
    );
  }

  get isDateTime() {
    return ["el-datetime", "datetime"].some(
      (name) => name === this.control.elementType
    );
  }

  get isTime() {
    return ["el-time", "el-time-picker", "time", "time-picker"].some(
      (name) => name === this.control.elementType
    );
  }

  get isBatchUpload() {
    return ["batch-upload", "upload-batch"].some(
      (name) => name === this.control.elementType
    );
  }

  get isProvinceCityAreaTownControl() {
    return [
      "province-city-area-town-control",
      "ProvinceCityAreaTownControl",
      "provinceCityAreaTownControl",
    ].some((name) => name === this.control.elementType);
  }

  get isProvinceCityAreaWithoutTownControl() {
    return [
      "province-city-area-without-town-control",
      "ProvinceCityArea",
      "provinceCityArea",
    ].some((name) => name === this.control.elementType);
  }

  get isUeditorControl() {
    return ["Ueditor", "editor", "u-editor"].some(
      (name) => name === this.control.elementType
    );
  }
}
