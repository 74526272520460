






































































































import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import {
  ActivityDto, ActivityManagementCreateOrUpdateDto,
  AttachmentHostType, CustomFormType,
  DataDictionaryDtoPagedResultDto,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import moment from "moment";

import CustomFormEdit from "@/components/CustomForm/Edit/index.vue";

@Component({
  components: {
    CustomFormEdit
  },
})
export default class EditFundMaintain extends Vue {
  @Ref("customForm") customForm!: CustomFormEdit;

  @Ref() readonly dataForm!: ElForm;
  dataId!: number;
  defaultData: ActivityManagementCreateOrUpdateDto = {};
  activityList:ActivityDto[]=[];
  loading = true;
  form:ActivityManagementCreateOrUpdateDto={
    extensionObject:{},
  }
  activityInfo:ActivityDto={
    provinceName:"",
    cityName:"",
    areaName:"",
    place: "",
    leader: {
      surname: ""
    },
    activityTypeDictionary: {
      displayName: ""
    }
  };
  checkInCount=0;

  get leaderName(){
    if(this.activityInfo && this.activityInfo.leader){
      return this.activityInfo.leader.surname!
    }
    return ""
  }

  get activityPlace(){
    if(this.activityInfo){
      return this.activityInfo.provinceName! + this.activityInfo.cityName! + this.activityInfo.areaName! + this.activityInfo.place!
    }
    return ""
  }

  get hostType() {
    return CustomFormType.ActivityManage;
  }

  get titleDisabled(){
    return this.dataId>0;
  }

  get ActivityTime(){
    if(this.activityInfo.activityStartTime&&this.activityInfo.activityEndTime){
      return moment(this.activityInfo.activityStartTime).format("YYYY-MM-DD HH:mm")+' 至 '+moment(this.activityInfo.activityEndTime).format("YYYY-MM-DD HH:mm");
    }
    else{
      return '';
    }
  }

  created() {
    this.getActivityList();
    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      api.activityManagement.getForEdit({id:this.dataId}).then(res=>{
        this.form=res;
        this.getActivityDetail()
      });
    }
  }

  getActivityDetail(){
    api.activity.get({id:this.form.activityId}).then((res) => {
      this.activityInfo=res;
      });
  }

  getActivityList(){
    api.activity.getAll({maxResultCount:65535}).then(res=>{
      this.activityList=res.items!;
    })
  }

  get getData() {
    if (this.dataId > 0) return api.activityManagement.getForEdit({id: this.dataId});
    else return this.defaultData;
  }

  handleSave() {

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        (this.$refs.customForm as any).validate(async (valid2: boolean) => {
          if (valid2) {
            const data = (this.customForm as any).getRequestData() as ActivityManagementCreateOrUpdateDto;
            data.extensionData=JSON.stringify(data.extensionObject);
            let submitForm:ActivityManagementCreateOrUpdateDto={
              activityId:this.form.activityId,
              formId:data.formId,
              extensionObject:data.extensionObject,
              extensionData:data.extensionData,
              id:this.dataId
            }

            let fn;
            if (this.dataId) fn = api.activityManagement.update;
            else fn = api.activityManagement.create;

            fn({body: submitForm}).then((res) => {
              if (this.dataId) {
                this.$message.success("更新成功");
              } else {
                this.$message.success("新增成功");
              }
              this.$router.push({
                name: "ActivityManagementList",
              });
            });
          } else {
            this.$message.error('请检查表单')
          }
        })
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  roleRule = {
    activityId: [
      {
        required: true,
        message: "活动标题 必填",
        trigger: "blur",
      },
    ],
    sex: [
      {
        required: true,
        message: "性别必选",
        trigger: "blur",
      },
    ],
    areaId: [
      {
        required: true,
        message: "居住地址必填",
        trigger: "blur",
      },
    ],
    address: [
      {
        required: true,
        message: "详细地址必填",
        trigger: "blur",
      },
    ],
  };
}
