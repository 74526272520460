








import {Component, Model, Prop, Vue, Watch} from "vue-property-decorator";
import {AttachmentHostType} from "@/api/appService";
import api from "@/api";

interface FileItem {
  id: number;
  name: string;
  url: string;
}

@Component({
  name: "UploadedFilesView",
})
export default class UploadedFilesView extends Vue {
  @Model("change") readonly attachments!: any;

  @Prop({required: true})
  hostId!: string | number;

  @Prop({required: true})
  hostType!: AttachmentHostType;

  existsFileList: FileItem[] = [];

  get headers() {
    return {
      "Abp-OrganizationUnitId": (this as any).$store.getters["user/ouId"] || "",
      "Abp-TenantId": (this as any).$store.getters["app/tenantId"],
      Authorization: `Bearer ${(this as any).$store.getters["user/token"]}`,
      ".AspNetCore.Culture": "c=zh-Hans|uic=zh-Hans",
    };
  }

  @Watch("hostType")
  onHostTypeChange() {
    this.loadExistsAttachments();
  }

  async created() {
    await this.loadExistsAttachments();
  }

  async loadExistsAttachments() {
    if (this.hostId && this.hostType) {
      await api.attachmentService
        .getAttachmentsByIds({
          ids: this.attachments || '',
        })
        .then((res) => {
          if (res) {
            this.existsFileList = res.map((attachment) => {
              return {
                id: attachment.id!,
                url: attachment.url!,
                name: attachment.fileName!,
              };
            });
          } else {
            this.existsFileList = [];
          }
        });
    }
  }
}
